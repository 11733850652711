import clsx from 'clsx';
import { ClipboardEvent, FC, KeyboardEvent, useRef, useState } from 'react';
import { FormError } from '../partials';

interface FormOtpProps {
  onChangeValue: (value: string, first?: boolean) => void
  className?: string
  placeholder?: string
  isError?: boolean
  errorMessage?: string
}

export const FormOtp: FC<FormOtpProps> = ({
  onChangeValue,
  className,
  placeholder = '0',
  isError,
  errorMessage,
}) => {
  const [otpValues, setOtpValues] = useState<string[]>(Array(6).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const submitButtonRef = useRef<HTMLButtonElement | null>(null)

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.currentTarget
    const key = e.key

    if (
      !/^[0-9]{1}$/.test(key) &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'Tab' &&
      !e.metaKey
    ) {
      e.preventDefault()
    }

    if ((key === 'Delete' || key === 'Backspace') && !input.value) {
      if (index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value
    e.target.placeholder = ''
    const newOtpValues = [...otpValues]

    if (/^[0-9]?$/.test(value)) {
      newOtpValues[index] = value
      setOtpValues(newOtpValues)
      onChangeValue(newOtpValues.join(''))

      if (value && index < otpValues.length - 1) {
        inputRefs.current[index + 1]?.focus()
      } else if (value && index === otpValues.length - 1) {
        submitButtonRef.current?.focus()
      }
    }
  }
  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = ''
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const text = e.clipboardData.getData('text')

    if (new RegExp(`^[0-9]{${otpValues.length}}$`).test(text)) {
      const digits = text.split('')
      const newOtpValues = digits.slice(0, otpValues.length)
      onChangeValue(newOtpValues.join(''))
      setOtpValues(newOtpValues)
      submitButtonRef.current?.focus()
    }
  }

  return (
    <div className={clsx('flex flex-col gap-2', className)}>
      <div className='flex items-center justify-between gap-3'>
        {otpValues.map((value, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type='text'
            maxLength={1}
            pattern='\d*'
            value={value}
            placeholder={placeholder}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleInput(e, index)}
            onPaste={handlePaste}
            onFocus={handleInputFocus}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.placeholder = placeholder
              }
            }}
            className={clsx(
              'w-16 h-16 text-center text-4xl bg-white font-medium font-national2Condensed text-neutral-70 placeholder:text-neutral-30 rounded-lg border border-solid focus:border-neutral-50 focus:ring-neutral-50 outline-none',
              {
                'border-neutral-30': !isError,
                'border-primary focus:border-primary focus:ring-primary': isError,
              }
            )}
            aria-label={`OTP digit ${index + 1}`}
          />
        ))}
      </div>
      {isError && <FormError>{errorMessage}</FormError>}
    </div>
  )
}
