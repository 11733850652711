import axios from 'axios'
import { BaseResponse, Pagination, QueryModel, Response } from 'src/app/models/api.types'
import { Admin, AdminQuery } from '../models/Admin.model'
import { ISaveRole, Permissions, Role, RoleAccessTemplate, RoleDetail } from '../models/Role.model'

// API URL
export const USER = '/admins/v1/auth'
export const ADMIN = '/admins/v2/admins'
export const ROLE = '/admins/v1/roles'
export const ROLE_ACCESS_TEMPLATE = '/api/v1/admins/user-roles/access-template'
export const PERMISSIONS = '/admins/v1/permissions'

// REACT QUERY KEY
export const LIST_ADMIN_KEY = 'list-admin'
export const DETAIL_ADMIN_KEY = 'detail-admin'
export const LIST_ROLE_KEY = 'list-role'
export const DETAIL_ROLE_KEY = 'detail-role'
export const EDIT_ROLE_KEY = 'edit-role'
export const ACCESS_TEMPLATE = 'access-template'

// User API
export const getListAdmin = (params?: AdminQuery) => {
  return axios.get<BaseResponse<Pagination<Admin[]>>>(ADMIN, {
    params,
  })
}

export const getDetailAdmin = (id?: string) => {
  return axios.get<BaseResponse<Response<Admin>>>(`${ADMIN}/${id}`)
}

export const saveAdmin = (data?: Admin, id?: string) => {
  if (id) return axios.put(`${ADMIN}/${id}`, data)

  return axios.post(ADMIN, data)
}

export const deleteAdmin = (id?: string, data?: any) => {
  return axios.delete(`${ADMIN}/${id}`, {
    data,
  })
}

export const verificationUserToken = ({ token, ...data }: any) => {
  return axios.post(`${USER}/forgot-password/verify`, data, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const renewPasswordUser = ({ token, data }: any) => {
  return axios.post(`${USER}/forgot-password/renew`, data, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const resetPasswordUser = (data?: any, headers?: any) => {
  return axios.post(`${USER}/reset-password`, data, {
    headers,
  })
}

export const forgotPassword = (data?: any, headers?: any) => {
  return axios.post(`${USER}/forgot-password`, data, {
    headers,
  })
}

export const getProfile = () => {
  return axios.get<BaseResponse<Response<Admin>>>(`${USER}/me`)
}

// Role API
export const getListRole = (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<Role[]>>>(ROLE, {
    params,
  })
}

export const getDetailRole = (id?: string) => {
  return axios.get<BaseResponse<Response<RoleDetail>>>(`${ROLE}/${id}`)
}

export const saveRole = (data?: ISaveRole, id?: string) => {
  if (id) return axios.put(`${ROLE}/${id}`, data)

  return axios.post(ROLE, data)
}

export const deleteRole = (id?: string) => {
  return axios.delete(`${ROLE}/${id}`)
}

export const getListPermissions = (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<Permissions[]>>>(PERMISSIONS, {
    params,
  })
}

export const getListAccessTemplateRole = () => {
  return axios.get<BaseResponse<Response<RoleAccessTemplate[]>>>(ROLE_ACCESS_TEMPLATE)
}

export const getListApproval = (params?: any) => {
  return axios.get<BaseResponse<Pagination<Admin[]>>>(`${ADMIN}/approvers`, {
    params,
  })
}