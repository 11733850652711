
export function phoneMasking(
  input: any,
  unmaskLength = 4,
  placeholder = "-"
) {
  if (!input) {
    return placeholder;
  }
  const string = input.toString() as string;
  const regexp = new RegExp(`(.+)(.{${unmaskLength}})$`);
  return string.replace(regexp, (_, __, ___) => __.replace(/./g, '*') + ___);
}
