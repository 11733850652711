import clsx from "clsx";
import { FC } from "react";
import { GButton } from "../Libs";
import FilterCard from "./FilterCard";
import { useFilterCount } from "src/app/hooks/filter-count-hook";

interface TableFilterProps {
  filterCount: number
  formik: any
  generatedForm: any
  resetFilterFn: any
  isShow: any
  setIsShow: any
  buttonJustify?: string
  buttonWidth?: string
  styleTitleFilter?: 'bold' | 'normal',
  className?: string
  maxHeight?: boolean
  filterContentClassName?: string,
  ignoreResetKeys?: string[]
}

const TableFilter: FC<TableFilterProps> = ({ formik, generatedForm, resetFilterFn, isShow, setIsShow, buttonJustify, buttonWidth, styleTitleFilter, className, maxHeight, filterContentClassName, ignoreResetKeys }) => {

  const { tempFilterCount } = useFilterCount(formik);

  return (
    <div className='relative'>
      <GButton
        size='large'
        variant='OUTLINED'
        startIcon='IconFilter'
        className={clsx('bg-netral border-primary hover:bg-primary-light-1', {
          'bg-primary-light-2': tempFilterCount > 0
        })}
        onClick={() => setIsShow(!isShow)}
      >
        {tempFilterCount > 1 && `${tempFilterCount}`} Filter {tempFilterCount > 0 && 'Applied'}
      </GButton>
      <FilterCard
        isShow={isShow}
        setIsShow={setIsShow}
        formik={formik}
        generatedForm={generatedForm}
        resetFilterFn={resetFilterFn}
        buttonJustify={buttonJustify}
        buttonWidth={buttonWidth}
        maxHeight={maxHeight}
        styleTitleFilter={styleTitleFilter}
        size={className}
        contentClassName={filterContentClassName}
        buttonDisabled={tempFilterCount < 1}
        ignoreResetKeys={ignoreResetKeys}
      />
    </div>
  )
}

export default TableFilter