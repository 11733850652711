import { useState } from "react";
import { phoneMasking } from "src/app/utils/string.utils";
import GIcon from "../Icon/GIcon";
import { PhoneMaskingProps } from "./PhoneMasking.types";

export function PhoneMasking({
  unmaskLength = 4,
  onChangeMask,
  text
}: Readonly<PhoneMaskingProps>) {

  const [isMasking, setIsMasking] = useState(false);

  const handleIsMasking = () => {
    const _isMasking = !isMasking;
    onChangeMask?.(_isMasking);
    setIsMasking(_isMasking);
  };

  return (
    <>
      {isMasking ? (text ?? '-') : phoneMasking(text, unmaskLength)}
      <GIcon
        className='ml-2 cursor-pointer'
        icon={isMasking ? 'IconEyeOffOutline' : 'IconEyeOnOutline'}
        onClick={handleIsMasking}
      />
    </>
  );
}
