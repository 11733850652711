import { useMutation } from "@tanstack/react-query"
import QRCode from 'qrcode'
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { GButton } from "src/app/components/Libs"
import { FormOtp } from "src/app/components/Libs/Form/Custom/FormOtp"
import BasicModal from "src/app/components/Widgets/ModalCollection/BasicModal"
import { useCopyText } from "src/app/hooks"
import { useErrorQuery } from "src/app/hooks/error-query-hook"
import { useWebTitle } from "src/app/hooks/title-hook"
import { RootState } from "src/setup"
import AuthRedux from "../../redux/AuthRedux"
import { isSetup2fa, verify2fa, verifySetup2fa } from "../../services/Auth.services"

const Login2FA: FC = () => {
  useWebTitle('Login')
  const dispatch = useDispatch()
  const { errorTemp } = useErrorQuery()
  const { type } = useParams<{ type: string }>()
  const history = useHistory()

  const [QRImage, setQRImage] = useState<string>('')
  const [QRImageUrl, setQRImageUrl] = useState<string>('')
  const [secretKey, setSecretKey] = useState('')
  const [error2fa, setError2fa] = useState<boolean>(false)
  const [errorMessage2fa, setErrorMessage2fa] = useState<string>('')
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(<></>)
  const [totp, setTotp] = useState('')
  const { temporaryToken, refreshToken, verifyToken2fa } = useSelector(
    (state: RootState) => state.auth,
    shallowEqual
  )

  useEffect(() => {
    QRCode.toDataURL(QRImageUrl, (err: any, image_data: string) => {
      setQRImage(image_data)
    })
  }, [QRImageUrl, secretKey])

  const errorHandler = (e: any) => {
      if (e.response.data.response_schema.response_code === 'KFCKU-ADMIN-0046') {
        const message = (
          <>
            {e.response.data.response_output.errors.hour !== 0 && (
              <>
                <div className='mb-12'>
                  For your security, your account has been temporarily locked due to 3 incorrect 2FA
                  attempts.
                </div>
                <div>
                  Please try again after{' '}
                  <strong>{e.response.data.response_output.errors.hour} hour</strong> or contact
                  your super admin for assistance.
                </div>
              </>
            )}
            {e.response.data.response_output.errors.hour === 0 && (
              <div className='my-12'>
                Your account has been locked due to multiple failed authentication attempts. Please
                contact your super admin to unlock your account.
              </div>
            )}
          </>
        )
        setErrorModal(true)
        setErrorMessage(message)
      }else{
        setError2fa(true)
        setErrorMessage2fa(e.response.data.response_schema.response_message)
      }
  }

  const checkSetup2fa = useMutation({
    mutationFn: () => {
      const config = {
        headers: {
          Authorization: `Bearer ${temporaryToken}`,
        },
      }

      return isSetup2fa(config)
    },
    onSuccess: (result) => {
      const data = result.data.response_output.detail
      setSecretKey(data.totp_secret)
      setQRImageUrl(data.qr_code_url)
      return data
    },
    onError: (e: any) => errorTemp(e.response.data.response_output.errors),
  })

  const setup2fa = useMutation({
    mutationFn: () => {
      const config = {
        headers: {
          Authorization: `Bearer ${temporaryToken}`,
        },
      }
      const data = {
        totp,
        totp_secret: secretKey,
      }

      return verifySetup2fa(data, config)
    },
    onSuccess: (result) => {
      dispatch(AuthRedux.actions.login(temporaryToken!, refreshToken!, verifyToken2fa!))
      return
    },
    onError: (e: any) => {
      errorHandler(e)
    },
  })

  const verifyOtp2fa = useMutation({
    mutationFn: () => {
      const config = {
        headers: {
          Authorization: `Bearer ${temporaryToken}`,
        },
      }
      const data = {
        totp,
      }

      return verify2fa(data, config)
    },
    onSuccess: (result) => {
      const accessToken = result.data.response_output.detail.access_token;
      dispatch(AuthRedux.actions.login(accessToken!, refreshToken!, verifyToken2fa!))
      return
    },
    onError: (e: any) => {
      errorHandler(e)
    },
  })

  useEffect(() => {
    if (temporaryToken !== 'undefined' && temporaryToken !== undefined) {
      checkSetup2fa.mutate()
    } else {
      history.push('/auth/login-email')
    }
  }, [temporaryToken])

  const copySecretKey = useCopyText({
    successMessage: 'Code has been copied to clipboard',
    scheme: 'success',
    content: secretKey,
  })

  return (
    <div data-testid='login-email-page' className='w-full'>
      <div className='flex flex-col gap-8'>
        <div>
          <div className='font-bold font-national2Condensed text-fs-4'>2-Factor Authentication</div>
          {type === 'set-up' && (
            <div className='font-normal text-fs-8 text-neutral-70'>
              Each time you sign in, in addition to your password, you’ll use Google Authenticator
              app to generate a one-time code.
            </div>
          )}
        </div>
        {type === 'set-up' && (
          <div>
            <div className='font-bold text-fs-8'>Step 1. Scan QR code</div>
            <div className='font-normal text-fs-8 text-neutral-70'>
              Scan the QR code below or manually enter the secret key into your Google Authenticator
              app.
            </div>
            <div className='flex items-center gap-4 p-4 mt-8 rounded-lg bg-neutral-10'>
              <img src={QRImage} className='h-full w-[200px]' />
              <div className='flex flex-col w-full gap-2 text-neutral-80'>
                <p className='font-bold'>Can’t scan QR code?</p>
                <p>Enter this secret key instead:</p>
                <input
                  type='text'
                  className='flex-1 w-full px-4 py-2 mr-2 border-gray-300 rounded-md bg-neutral-20'
                  value={secretKey}
                  readOnly
                />
                <button
                  type='button'
                  className='flex items-center gap-2 px-4 py-2 bg-white border w-fit border-neutral-70 rounded-xl'
                  onClick={() => copySecretKey()}
                >
                  <span>
                    <svg
                      width='16'
                      height='17'
                      viewBox='0 0 16 17'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.6667 9.83333H12.6667C13.403 9.83333 14 9.23638 14 8.5V3.83333C14 3.09695 13.403 2.5 12.6667 2.5H8C7.26362 2.5 6.66667 3.09695 6.66667 3.83333V4.83333M3.33333 7.16667H8C8.73638 7.16667 9.33333 7.76362 9.33333 8.5V13.1667C9.33333 13.903 8.73638 14.5 8 14.5H3.33333C2.59695 14.5 2 13.903 2 13.1667V8.5C2 7.76362 2.59695 7.16667 3.33333 7.16667Z'
                        stroke='#334155'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>
                  Copy
                </button>
              </div>
            </div>
          </div>
        )}
        <div>
          {type === 'set-up' && (
            <div className='font-bold text-fs-8'>Step 2. Get verification Code</div>
          )}
          <div className='font-normal text-fs-8 text-neutral-70'>
            Enter the 6-digit authenticator code from Google Authenticator
          </div>
          <FormOtp
            onChangeValue={(val) => setTotp(val)}
            className='py-4 mx-auto'
            placeholder='0'
            isError={error2fa}
            errorMessage={errorMessage2fa}
          />
        </div>
        <div>
          <GButton
            type='submit'
            size='large'
            className='w-full'
            loading={setup2fa.isLoading}
            disabled={setup2fa.isLoading}
            onClick={() => {
              type === 'set-up' ? setup2fa.mutate() : verifyOtp2fa.mutate()
            }}
          >
            Verify
          </GButton>
        </div>
      </div>

      <BasicModal
        show={errorModal}
        handleClose={() => {
          setErrorModal(false)
        }}
        handleSuccess={() => {
          setErrorModal(!errorModal)
        }}
        header='Account Temporarily Locked'
        headerWeight='bold'
        positiveLabel='Ok'
        loading={false}
      >
        {errorMessage}
      </BasicModal>
    </div>
  )
}

export default Login2FA