import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from 'src/app/components/Libs'
import BodyTableV2 from 'src/app/components/Widgets/BodyTableV2'
import EmptyTable from 'src/app/components/Widgets/EmptyTable'
import BasicModal from 'src/app/components/Widgets/ModalCollection/BasicModal'
import { useHeaderToast } from 'src/app/context/ToastContext'
import { useErrorQuery } from 'src/app/hooks/error-query-hook'
import { useFilterCheckbox } from 'src/app/hooks/filter-checkbox-hook'
import { usePaginationQuery } from 'src/app/hooks/pagination-query-hook'
import { Order } from 'src/app/models/api.types'
import { StatusBestSeller } from 'src/app/modules/general/templates'
import { useBannerDeletion } from '../../../hooks/useBanner.hook'
import { BannerModel, BannerQuery } from '../../../models/Banner.model'
import PromotionScreens from '../../../Screens'
import { LIST_BANNER_KEY, deleteBanner, getListBanner } from '../../../services/Banner.services'
import { useTableBanner } from '../../../templates/banner'

const SectionList: FC = () => {
  const history = useHistory()
  const moduleName = 'section-banner';
  const { addPageToasts } = useHeaderToast()

  const [isEmptyTable, setIsEmptyTable] = useState<'Empty' | 'NotEmpty'>('NotEmpty')
  const { errorTemp } = useErrorQuery()

  const handleEmptyTable = useCallback((total?: number) => {
    setIsEmptyTable(total === 0 ? 'Empty' : 'NotEmpty')
  }, [])

  const urlCreateBanner = PromotionScreens.ADD_SECTION_BANNER.PATH

  const handleCreateButton = () => {
    history.push(urlCreateBanner)
  }

  const renderTable = usePaginationQuery<BannerModel[], BannerQuery>({
    queryKey: [LIST_BANNER_KEY],
    queryFn: async (params) => {
      if (!params?.sort) {
        params.sort = 'start_date'
        params.order = Order.DESC
      }
      const payload = await getListBanner(params, moduleName)
      const data = payload.data.response_output?.list?.content
      const pagination = payload.data.response_output?.list?.pagination

      setTotal(pagination?.total_rows as number)

      const { limit, page, ...restQuery } = state.query
      if (Object.keys(restQuery).length === 0) handleEmptyTable(pagination?.total_rows)

      return data
    },
  })

  const { state, setTotal, setPage, refetch, formikFilter } = renderTable

  const generatedStatuses = useFilterCheckbox(formikFilter, 'statuses', 'All Status', StatusBestSeller)

  const { headersSectionBanner, deleteModal, setDeleteModal, generatedForm, selected } = useTableBanner(
    state,
    generatedStatuses
  )

  const { deleteFn } = useBannerDeletion({
    selected,
    moduleName,
    deleteBanner,
    addPageToasts,
    setDeleteModal,
    setPage,
    refetch,
    errorTemp
  });

  return (
    <>
      <Card.Body className='mb-10'>
        {isEmptyTable === 'Empty' && (
          <EmptyTable
            title='No banner are available'
            body=' Click the button below to create a new Banner.'
            buttonTitle='Create Banner'
            buttonHref={urlCreateBanner}
          />
        )}
        {isEmptyTable === 'NotEmpty' && (
          <BodyTableV2
            renderTable={renderTable}
            isFilter={true}
            filterCalendar={true}
            titleCalendar={'Section Date Filter'}
            generatedForm={generatedForm}
            headers={headersSectionBanner}
            createButton={true}
            onCreateButton={handleCreateButton}
            titleCreateButton="Create List"
            placeholder='Search content'
            permissionCreate='section-banner.create'
          />
        )}
      </Card.Body>

      <BasicModal
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSuccess={() =>
          deleteFn.mutate()
        }
        header='Are you sure want to delete this section banner ?'
        positiveLabel='Delete'
        negativeLabel='Cancel'
        loading={deleteFn.isLoading}
      >
        Are you sure want to delete {selected?.name} ? Delete Offers will remove data from this cms/dashboard and mobile apps. Customer will not see this Banner.
      </BasicModal>
    </>
  )

}

export default SectionList
