import {forwardRef} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export interface RecaptchaProps {
  onVerify: (token: string | null) => void
  isError?: boolean
  errorMessage?: string
}

const FormRecaptchaV2 = forwardRef<ReCAPTCHA, RecaptchaProps>((props, ref) => {
  const {onVerify, isError, errorMessage} = props
  const baseKeyRecaptcha = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  return (
    <div className='w-fit'>
      <div className='flex justify-start flex-col'>
        <ReCAPTCHA ref={ref} sitekey={baseKeyRecaptcha!} onChange={onVerify} />
        {isError && <span className='text-primary text-sm font-medium'>{errorMessage}</span>}
      </div>
    </div>
  )
})

export {FormRecaptchaV2}
