import clsx from 'clsx'
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { useDebounceEffect } from 'src/app/hooks/debounce-hook'
import { FormText, GButton } from '../Libs'
import QueryTable from '../Libs/Table/QueryTable/QueryTable'
import TableError from '../Libs/Table/TableError'
import CalendarModal from './ModalCollection/CalendarModal'
import TableFilter from './TableFilter'
import { DateObject } from 'react-multi-date-picker'
import dayjs from 'dayjs'
import { useClickOutside } from 'src/app/hooks/click-outside-hook'
interface EmptyTable {
  title: string
  body: string
}

interface Props {
  renderTable: any
  headers: any
  generatedForm?: any
  filterCount?: number
  formik?: any
  resetFilterFn?: any
  isShow?: any
  setIsShow?: any
  isShowCalendar?: any
  setIsShowCalendar?: any
  isShowDownload?: any
  setIsShowDownload?: any
  isSearch?: boolean
  isFilter?: boolean
  isPagination?: boolean
  filterCalendar?: boolean
  filterDownload?: boolean
  stickyHeader?: boolean
  titleCalendar?: string
  placeholder?: string
  notFoundType?: 'TYPE_1' | 'TYPE_2'
  onResetFilterClick?: MouseEventHandler
  buttonJustify?: string
  buttonWidth?: string
  styleTitleFilter?: 'bold' | 'normal'
  emptyTable?: EmptyTable
  downloadButton?: React.ReactNode
  filterClassName?: string
  maxHeight?: boolean
  filterContentClassName?: string;
  ignoreResetKeys?: string[]
}

const BodyTableTemplate: FC<Props> = ({
  generatedForm,
  resetFilterFn,
  renderTable,
  headers,
  isShowDownload,
  setIsShowDownload,
  filterCount = 0,
  isSearch = true,
  isFilter = true,
  filterCalendar = false,
  filterDownload = false,
  stickyHeader = false,
  titleCalendar,
  placeholder = 'Search',
  notFoundType = 'TYPE_1',
  isPagination = true,
  buttonJustify,
  buttonWidth,
  onResetFilterClick,
  styleTitleFilter = 'normal',
  emptyTable,
  downloadButton = null,
  filterClassName,
  maxHeight = true,
  filterContentClassName,
  ignoreResetKeys
}) => {

  const [, isShowCalendar, setIsShowCalendar] = useClickOutside(false);
  const [, isShow, setIsShow] = useClickOutside(false);
  const [dateRange, setDateRange] = useState<DateObject[]>([]);

  const formik = renderTable.formikFilter;
  const hasDateRange = !formik.dirty && !!formik.values?.start_date && !!formik.values?.end_date;

  const searchFn = useDebounceEffect(
    useCallback(
      (search: string) => renderTable.setQuery((prev: any) => ({ ...prev, search })),
      //eslint-disable-next-line
      [renderTable.setQuery]
    ),
    500
  )


  useEffect(
    () => {
      if (hasDateRange) {
        const _dateRange = [new DateObject(formik.values?.start_date), new DateObject(formik.values?.end_date)];
        setDateRange(_dateRange);
      }
    },
    [hasDateRange]
  );

  useEffect(() => {
    if (dateRange !== undefined && dateRange.length === 2) {
      const startDate = dateRange[0].format('YYYY-MM-DD')
      const endDate = dateRange[1].format('YYYY-MM-DD')

      formik.setFieldValue('start_date', startDate)
      formik.setFieldValue('end_date', endDate)
    }
  }, [dateRange.length])


  const onResetCalendar = () => {
    setDateRange([]);
    setIsShowCalendar(false)
    formik.setFieldValue('start_date', '')
    formik.setFieldValue('end_date', '')
    renderTable.setQuery((prev: any) => ({
      ...prev,
      start_date: null,
      end_date: null
    }));
  }

  const onSubmitCalendar = () => {
    const startDate = formik.values?.start_date;
    const endDate = formik.values?.end_date;
    setIsShowCalendar(false);
    if (!!startDate && !!endDate) {
      renderTable.setQuery((prev: any) => ({
        ...prev,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD')
      }));
    }
  }

  return (
    <>
      {!renderTable.isError ? (
        <>
          {(!renderTable.isFetching || renderTable.state.query.search !== '') && (
            <div className='flex items-center justify-between'>
              {(isFilter || filterCalendar || filterDownload) && (
                <div className='flex'>
                  {isFilter && (
                    <TableFilter
                      filterCount={filterCount}
                      formik={formik}
                      generatedForm={generatedForm}
                      resetFilterFn={resetFilterFn}
                      isShow={isShow}
                      setIsShow={setIsShow}
                      buttonJustify={buttonJustify}
                      buttonWidth={buttonWidth}
                      styleTitleFilter={styleTitleFilter}
                      className={filterClassName}
                      maxHeight={maxHeight}
                      filterContentClassName={filterContentClassName}
                      ignoreResetKeys={ignoreResetKeys}
                    />
                  )}
                  {filterCalendar && (
                    <GButton
                      size='large'
                      variant='OUTLINED'
                      startIcon='IconCalendarRounded'
                      className={clsx('bg-netral border-primary hover:bg-primary-light-1 ml-4', { 'bg-primary-light-2 border-none': hasDateRange })}
                      onClick={() => setIsShowCalendar(!isShowCalendar)}
                    >
                      {hasDateRange ? `${formik.values?.start_date} - ${formik.values?.end_date}` : 'Select Date'}
                    </GButton>
                  )}
                  {filterDownload && downloadButton === null && (
                    <GButton
                      size='large'
                      variant='OUTLINED'
                      startIcon='IconDownload'
                      className='ml-4 bg-netral border-primary hover:bg-primary-light-1'
                      onClick={() => setIsShowDownload(!isShowDownload)}
                    >
                      Download
                    </GButton>
                  )}
                  {filterDownload && downloadButton !== null && (
                    <>
                      {downloadButton}
                    </>
                  )}
                </div>
              )}
              {isSearch && (
                <div className='md:min-w-[400px] mr-4'>
                  <FormText
                    placeholder={placeholder}
                    prependInnerIcon='IconSearch'
                    appendInnerIcon={renderTable.state.query.search ? 'IconClose' : undefined}
                    appendInnerClassname='text-neutral-60'
                    clickAppendInnerIcon={() => searchFn('')}
                    onChange={(e) => searchFn(e.target.value)}
                    name='search'
                    defaultValue={formik.values?.search}
                  />
                </div>
              )}
            </div>
          )}

          <QueryTable
            render={renderTable}
            headers={headers}
            notFoundType={notFoundType}
            onResetFilterClick={onResetFilterClick}
            showPagination={isPagination}
            isStickyHeader={stickyHeader}
            isFilter={(isFilter || filterCalendar || filterDownload || isSearch)}
            emptyTable={emptyTable}
          />

          <CalendarModal
            title={titleCalendar || 'Date Filter'}
            isShowCalendar={isShowCalendar}
            setIsShowCalendar={setIsShowCalendar}
            setSelectedDate={() => { }}
            value={dateRange}
            setValue={setDateRange}
            reset={onResetCalendar}
            apply={onSubmitCalendar}
          />
        </>
      ) : (
        <TableError />
      )}
    </>
  )
}

export default BodyTableTemplate
