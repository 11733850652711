import axios from 'axios'

//new
// export const LOGIN_BY_EMAIL = `/api/v1/admins/login/email`
export const LOGIN_BY_EMAIL = `/admins/v1/auth`
export const LOGIN_BY_CAPTCHA = `/admins/v2/auth`
export const IS_SETUP_2FA = `/admins/v2/auth/2fa/setup`
export const VERIFY_SETUP_2FA = `/admins/v2/auth/2fa/verify-setup`
export const VERIFY_2FA = `/admins/v2/auth/2fa/verify`

//old
export const CHANGE_PASSWORD = `/api/v1/auth/change-password`
export const RESET_PASSWORD = `/api/v1/auth/forgot-password`
export const REQUEST_RESET_PASSWORD = `/api/v1/auth/forgot-password-confirmation`
export const VERIFY_RESET_PASSWORD = `/api/v1/auth/forgot-password-detail`

export const loginByEmail = (data?: any, cancelToken?: any) => {
  return axios.post(LOGIN_BY_CAPTCHA, data, {cancelToken})
}

export const isSetup2fa = (config?: any) => {
  return axios.post(IS_SETUP_2FA, {}, config)
}

export const verifySetup2fa = (data?: any, config?: any) => {
  return axios.post(VERIFY_SETUP_2FA, data, config)
}

export const verify2fa = (data?: any, config?: any) => {
  return axios.post(VERIFY_2FA, data, config)
}

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config)
}

export const requestResetPassword = (data?: any, cancelToken?: any) => {
  return axios.post(REQUEST_RESET_PASSWORD, data, {
    cancelToken,
  })
}

export const verifyResetPassword = (id: string) => {
  return axios.get(`${VERIFY_RESET_PASSWORD}/${id}`)
}

export const saveResetPassword = (id?: string, data?: any) => {
  return axios.put(`${RESET_PASSWORD}/${id}/new-password`, data)
}
