import { useEffect, useState } from 'react'

type FilterValue = string | boolean | object | undefined

interface FilterValues {
  [key: string]: FilterValue
}
export interface FilterCountResult {
  tempFilterCount: number
  setTempFilterCount: React.Dispatch<React.SetStateAction<number>>
}

export const useFilterCount = (deps: { values: FilterValues, dirty: boolean }): FilterCountResult => {

  const [tempFilterCount, setTempFilterCount] = useState<number>(0)

  useEffect(() => {
    if (!deps.dirty) {
      const updatedValues = { ...deps.values }
      delete updatedValues.start_date
      delete updatedValues.end_date
      delete updatedValues.search

      const mapFilteredValue: FilterValue[] = Object.values(updatedValues)

      const count = mapFilteredValue.reduce((accumulator, value) => {
        if (typeof value === 'string' && value !== '') return accumulator + 1

        if (typeof value === 'boolean' && value !== undefined) return accumulator + 1

        if (Array.isArray(value)) {
          return value.reduce((_, __) => _ + (__ === undefined || __ === '' ? 0 : 1), accumulator);
        }

        if (typeof value === 'object') {
          if (Object.prototype.toString.call(value) === '[object Date]') return accumulator + 1
        }

        return accumulator
      }, 0)

      setTempFilterCount(count)
    }
  }, [deps.dirty])

  return { tempFilterCount, setTempFilterCount }
}
