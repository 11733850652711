import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LayoutProvider } from './context/LayoutContext'
import { LayoutSplashScreen } from './context/SplashScreenContext'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routers/Routes'
import { NuqsAdapter } from 'nuqs/adapters/react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  basename?: string
}

const App: FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename='/cms'>
        <NuqsAdapter>
          <QueryClientProvider client={queryClient}>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </QueryClientProvider>
        </NuqsAdapter>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
