import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ENV_STORAGE_KEY, env } from 'src/app/utils/env-utils'
import { getProfile } from '../../permissions/services/Permission.services'
import { AuthModel } from '../models/Auth.model'

namespace AuthRedux {
  const STORAGE_KEY = () => {
    return env.REACT_APP_STORAGE_KEY || 'BB-auth'
  }

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export interface IAuthState {
    fetchingUser?: FetchingUserState
    user?: any
    accessToken?: string
    verifyToken2fa?: boolean
    refreshToken?: string
    temporaryToken?: string
    temporaryEmail?: string
    requireProtectedPath?: string
    protectedRoute?: string
  }

  export type FetchingUserState = 'failed' | 'success' | 'loading'

  export const actionTypes = {
    Login: '[Login] Action',
    Set2fa: '[Set 2fa] Action',
    Logout: '[Logout] Action',
    UserRequested: '[Request User] Action',
    RefreshToken: '[Refresh Token] Action',
    UserLoaded: '[Load User] Auth API',
    SetUser: '[Set User] Action',
    FetchingUser: '[Fetching User] Action',
    SetLevel: '[Set Level] Action',
    ExpirdedUser: '[Expired User] Action',
    SetProtectionPath: '[Set Admin Guard] Action',
  }

  export const initialAuthState: IAuthState = {
    user: undefined,
    accessToken: undefined,
    verifyToken2fa: false,
    refreshToken: undefined,
    temporaryToken: undefined,
    temporaryEmail: undefined,
    requireProtectedPath: undefined,
    protectedRoute: undefined,
  }

  export const reducer = () =>
    persistReducer<IAuthState, ActionWithPayload<IAuthState>>(
      {
        storage,
        key: STORAGE_KEY(),
        whitelist: ['accessToken', 'refreshToken', 'user'],
      },
      (state: IAuthState = initialAuthState, action = { type: '' }) => {
        switch (action.type) {
          case actionTypes.Login:
          case actionTypes.SetLevel: {
            const accessToken = action.payload?.accessToken
            const refreshToken = action.payload?.refreshToken
            const verifyToken2fa = action.payload?.verifyToken2fa
            const user = action.payload?.user
            return { accessToken, refreshToken, user, verifyToken2fa }
          }

          case actionTypes.Set2fa: {
            const temporaryToken = action.payload?.temporaryToken
            const verifyToken2fa = action.payload?.verifyToken2fa
            const refreshToken = action.payload?.refreshToken
            return { temporaryToken, refreshToken, verifyToken2fa }
          }

          case actionTypes.Logout: {
            return initialAuthState
          }

          case actionTypes.UserRequested: {
            return { ...state }
          }

          case actionTypes.RefreshToken: {
            if (!state.refreshToken) return state
            return {
              ...state,
              accessToken: action.payload?.accessToken ?? state.accessToken,
              refreshToken: action.payload?.refreshToken ?? state.refreshToken,
            }
          }

          case actionTypes.UserLoaded:
          case actionTypes.SetUser: {
            const user = action.payload?.user
            return { ...state, user, fetchingUser: 'success' as FetchingUserState }
          }

          case actionTypes.FetchingUser: {
            const fetchingUser = action.payload?.fetchingUser
            return { ...state, fetchingUser }
          }

          case actionTypes.ExpirdedUser: {
            const temporaryToken = action.payload?.temporaryToken
            const temporaryEmail = action.payload?.temporaryEmail
            return { ...state, temporaryToken, temporaryEmail }
          }

          case actionTypes.SetProtectionPath: {
            return { ...state, ...action.payload }
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    login: (accessToken: string, refreshToken: string, verifyToken2fa?: boolean) => ({
      type: actionTypes.Login,
      payload: { accessToken, refreshToken, verifyToken2fa },
    }),
    set2fa: (temporaryToken: string, refreshToken?: string, verifyToken2fa?: boolean) => ({
      type: actionTypes.Set2fa,
      payload: { temporaryToken, refreshToken, verifyToken2fa },
    }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: () => ({
      type: actionTypes.UserRequested,
    }),
    fulfillToken: ({ token, refreshToken }: AuthModel) => ({
      type: actionTypes.RefreshToken,
      payload: { accessToken: token, refreshToken },
    }),
    fulfillUser: (user: any) => ({ type: actionTypes.UserLoaded, payload: { user } }),
    setUser: (user: any) => ({ type: actionTypes.SetUser, payload: { user } }),
    setFetchingUser: (fetchingUser: 'failed' | 'success' | 'loading') => ({
      type: actionTypes.FetchingUser,
      payload: { fetchingUser },
    }),
    setTemporaryToken: (temporaryToken: string, temporaryEmail: string) => ({
      type: actionTypes.ExpirdedUser,
      payload: { temporaryToken, temporaryEmail },
    }),
    setProtectionPath: (path: string) => ({
      type: actionTypes.SetProtectionPath,
      payload: {
        requireProtectedPath: path,
        protectedRoute: window.location.pathname
      },
    })
  }

  export function* saga() {
    yield takeLatest(actionTypes.RefreshToken, function* loginSaga() {
      yield put(actions.requestUser())
    })
    yield takeLatest(actionTypes.Login, function* loginSaga() {
      yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.Logout, function* loginSaga() {
      const whitelist = {
        [ENV_STORAGE_KEY]: localStorage.getItem(ENV_STORAGE_KEY),
      }
      yield localStorage.clear()
      Object.entries(whitelist).forEach(([key, value]) => {
        if (value) localStorage.setItem(key, value)
      })
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested(_action: any) {
      try {
        yield put(actions.setFetchingUser('loading'))
        const {
          data: {
            response_output: { detail },
          },
        } = yield call(getProfile)

        yield put(actions.fulfillUser(detail))
      } catch {
        yield put(actions.setFetchingUser('failed'))
      }
    })
  }
}

export default AuthRedux
