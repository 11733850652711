
const ReportScreens = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
    PERMISSION: 'report.get-list',
  },
  REPORT_CUSTOMER: {
    PATH: '/report/report-customer',
    TITLE: 'Report Customer',
    TITLE_ALT: 'Report Customer',
    PERMISSION: 'report-customer.get-list',
  },
  LIST_REPORT_CUSTOMER: {
    PATH: '/report/report-customer/:customer/:time',
    TITLE: 'Report Customer',
    TITLE_ALT: 'Report Customer',
    PERMISSION: 'report-customer.get-list',
  },
  REPORT_ORDER: {
    PATH: '/report/report-order',
    TITLE: 'Report Order',
    TITLE_ALT: 'Report Order',
    PERMISSION: 'report-order.get-list',
  },
  LIST_REPORT_ORDER: {
    PATH: '/report/report-order/:order/:time',
    TITLE: 'Report Order',
    TITLE_ALT: 'Report Order',
    PERMISSION: 'report-order.get-list',
  },
}

export default ReportScreens;
