import { getIn } from 'formik'
import { ChangeEvent, FC } from 'react'
import { FileRejection } from 'react-dropzone'
import { cn } from 'src/app/utils/cn-utils'
import { validateImage, ValidateImageParams } from 'src/app/utils/input-utils'
import { twMerge } from 'tailwind-merge'
import { FormEditor, FormUploadImage } from '../Custom'
import { ValidateFormUploadImage } from '../Custom/ValidateFormUploadImage'
import {
  FormCheckbox,
  FormChecklist,
  FormDatepicker,
  FormLabel,
  FormMultiSelect,
  FormMultiSelectAll,
  FormPassword,
  FormRadio,
  FormSelect,
  FormText,
  FormTextarea,
  MultiSelectOption,
  SelectOption,
  UploadCsv
} from '../partials'
import {
  FormSelectAsync
} from '../partials/Select/SelectAsync.component'
import {
  CreateFormProps,
  GFormProps,
  InputCSV
} from './FormBuilder.types'
import {
  getFormLabelStyle,
  getFormListStyle
} from './FormBuilder.utils'
import { DateObject } from 'react-multi-date-picker'

const commonProps = (props: CreateFormProps) => {
  const { arrayValue, arrayClassName, data, formik } = props
  const { className, value } = data
  const error = getIn(formik.errors, value as string);
  const touched = getIn(formik.touched, value as string);

  return {
    className: arrayValue ? cn(arrayClassName, className) : cn(className),
    error: error as string,
    touched: touched as boolean,
  }
}

const FormTextBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'text' || props.data.type === 'email') {
    const { value, onChange, isShow, ...rest } = props.data

    const name = value?.toString()!;
    return (
      <FormText
        {...props.formik.getFieldProps(name)}
        {...commonProps(props)}
        name={name}
        onChange={(e) => {
          props.formik.setFieldTouched(name, true);
          if (typeof onChange) {
            props.formik.handleChange(e);
          }
        }}
        {...rest}
      />
    )
  }
  return null
}

const FormTextareaBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'textarea') {
    const { value, isShow, ...rest } = props.data

    return (
      <FormTextarea
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        name={value as string}
        {...rest}
      />
    )
  }
  return null
}

const FormPasswordBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'password') {
    const { value, onChange, isShow, ...rest } = props.data

    return (
      <FormPassword
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        name={value as string}
        minLength={8}
        {...rest}
      />
    )
  }
  return null
}

const FormSelectBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'select') {
    const { value, changedValue, isShow, ...rest } = props.data

    return (
      <FormSelect
        {...commonProps(props)}
        changedValue={(payload: SelectOption) => {
          props.formik.setFieldTouched(`${value}`);
          changedValue
            ? changedValue(payload)
            : props.formik.setFieldValue(`${value}`, payload.value)
        }}
        selectedValue={props.formik.values[`${value}`]}
        {...rest}
      />
    )
  }
  return null
}

const FormSelectAsyncBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'select-async') {
    const { changedValue, value, isShow, ...rest } = props.data;
    const name = `${value}`;
    const defaultValue = getIn(props.formik.values, name);

    return (
      <FormSelectAsync
        {...commonProps(props)}
        defaultValue={defaultValue}
        changedValue={(payload: SelectOption) => {
          changedValue
            ? changedValue(payload)
            : props.formik.setFieldValue(name, payload)
        }}
        {...rest}
      />
    )
  }
  return null
}

const FormMultiSelectBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'multi-select') {
    const { value, changedValue, options, isShow, ...rest } = props.data

    return (
      <FormMultiSelect
        {...commonProps(props)}
        changedValue={(payload, actionMeta) => {
          changedValue
            ? changedValue(payload, actionMeta)
            : props.formik.setFieldValue(`${value}`, payload)
        }}
        selectedValue={props.formik.values[`${value}`]}
        options={options as MultiSelectOption[]}
        {...rest}
      />
    )
  }
  return null
}

const FormMultiSelectAllBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'multi-select-all') {
    const { value, onChangeValue, isShow, ...rest } = props.data
    const name = `${value}`;
    const fieldProps = props.formik.getFieldProps(name);

    return (
      <FormMultiSelectAll
        {...commonProps(props)}
        selectedValue={fieldProps.value}
        {...rest}
        onChangeValue={(payload, actionMeta) => {
          Object.assign(props.formik.touched, { [name]: true });
          onChangeValue
            ? onChangeValue(payload, actionMeta)
            : props.formik.setFieldValue(name, payload)
        }}
      />
    );
  }
  return null
}

const FormRadioBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'radio') {
    const { value, isShow, ...rest } = props.data

    return (
      <FormRadio
        {...commonProps(props)}
        checkedValue={props.formik.values[`${value}`]}
        changedValue={(payload: any) => {
          props.formik.setFieldValue(`${value}`, payload);
          props.formik.setFieldTouched(`${value}`);
        }}
        {...rest}
      />
    )
  }
  return null
}

const FormCheckboxBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'checkbox') {
    const { value, checkedValue, activeValue, inactiveValue, isShow, ...rest } = props.data

    return (
      <FormCheckbox
        {...commonProps(props)}
        checkedValue={checkedValue ?? props.formik.values[`${value}`]}
        value={value as string}
        changedValue={(e: any) => {
          props.formik.setFieldValue(`${value}`, e.target.checked ? activeValue : inactiveValue)
          props.formik.setFieldTouched(`${value}`);
        }}
        activeValue={props.data.activeValue}
        inactiveValue={props.data.inactiveValue}
        {...rest}
      />
    )
  }
  return null
}

const FormImageBuild: FC<CreateFormProps> = (props) => {

  if (props.data.type === 'image') {
    return (
      <ValidateFormUploadImage
        {...commonProps(props)}
        {...props.data}
        formik={props.formik}
      />
    );
  }
  return null;
};
const _FormImageBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'image') {
    const {
      value,
      validateImage: _validateImage = {},
      message = 'Upload Image (JPG/PNG)',
      requirements,
      imgClassname,
      acceptFile,
      actionButtonPlacement,
    } = props.data;

    const name = `${value}`;
    const previewName = `${name}_preview`;

    const validateImageParams: ValidateImageParams = {
      field: `${name}`,
      preview_field: `${previewName}`,
      setFieldError: props.formik.setFieldError,
      setFieldValue: props.formik.setFieldValue,
      maxSize: 1,
      ratio: '16/9',
      ..._validateImage
    };

    const handleDropImage = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      props.formik.setFieldTouched(name, true);
      validateImage({
        image: acceptedFiles[0],
        rejects: rejectedFiles,
        ...validateImageParams
      });
    };
    const ratioStyle = () => {
      switch (_validateImage.ratio) {
        case '9/16':
          return 'w-60 aspect-[9/16]';
        case '16/9':
          return 'max-h-[220px] aspect-[16/9]';
        case '1/1':
          return 'max-h-60 aspect-[1/1]';
        default:
          return 'max-h-60';
      }
    };

    return (
      <div className={twMerge('flex items-center', imgClassname)}>
        <FormUploadImage
          {...commonProps(props)}
          title='Image'
          message={message}
          onDrop={handleDropImage}
          acceptFile={acceptFile}
          onClear={() => {
            props.formik.setFieldValue(previewName, '')
            props.formik.setFieldValue(`${name}`, '')
          }}
          preview={getIn(props.formik.values, previewName)}
          className={ratioStyle()}
          actionButtonPlacement={actionButtonPlacement}
        />
        <div className='grow'>
          <ul className='text-sm leading-8 list-disc'>
            {Array.isArray(requirements) ?
              requirements.map((_) => <li key={_?.toString()}>{_}</li>) :
              <>
                <li>
                  <span className='underline'>Size:</span> {requirements?.maxSize ?? `Max size of file is ${validateImageParams?.maxSize} MB.`}
                </li>
                <li>
                  <span className='underline'>File format:</span> {requirements?.format ?? "only allow .jpg & .png"}
                </li>
                <li>
                  <span className='underline'>File format:</span> {requirements?.ratio ?? `${validateImageParams?.ratio} Ratio`}
                </li>
              </>
            }
          </ul>
        </div>
      </div>
    );
  }
  return null;
}

const FormChecklistBuild: FC<CreateFormProps> = (props) => {
  const { type, value: name } = props.data;
  if (type === 'checklist' && typeof name === 'string') {

    const value = props.formik.values?.[name] ?? [];

    const _options = props.data.options ?? [];
    const options = _options.map((_) => ({ ..._, checked: value?.includes(_.value) }));

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      props.formik.setFieldTouched(name);
      if (event.target.checked) {
        const _value = [...value, event.target.value];
        props.formik.setFieldValue(name, _value);
      } else {
        const _value = value.filter((_: string) => _ !== event.target.value);
        props.formik.setFieldValue(name, _value);
      }
    };

    return (
      <FormChecklist
        {...commonProps(props)}
        disabled={props.data?.disabled}
        onChange={handleChange}
        options={options}
      />
    )
  }
  return null;
}

const FormDatepickerBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'date') {
    const { value, isShow, ...rest } = props.data

    const fieldProps = props.formik.getFieldProps(`${value}`);
    if (props.data.parseAsDate && !!fieldProps.value && typeof fieldProps.value === 'string') {
      Object.assign(fieldProps, { value: new DateObject(fieldProps.value) });
    }
    return (
      <FormDatepicker
        {...fieldProps}
        {...commonProps(props)}
        onChange={(payload: any) => {
          props.formik.setFieldTouched(`${value}`);
          props.formik.setFieldValue(`${value}`, payload?.toDate())
        }}
        id={value?.toString()}
        {...rest}
      />
    )
  }
  return null
}

const FormEditorBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'form-editor') {
    const { value, ...rest } = props.data;
    const name = `${value}`;
    return (
      <FormEditor
        {...commonProps(props)}
        {...props.formik.getFieldProps(name)}
        placeholder={'Enter description'}
        toolbar='bold italic link bullist numlist'
        {...rest}
        onFocus={() => props.formik.setFieldTouched(name, true)}
        onChange={(_) => props.formik?.setFieldValue(name, _)}
      />
    );
  }
  return null;
}

const FormFileCsvBuild: FC<CreateFormProps> = (props) => {
  const { type, value, ...rest } = props.data as InputCSV;
  if (type === 'file-csv') {
    const name = `${value}`;

    return (
      <UploadCsv
        {...commonProps(props)}
        onSuccess={(_) => props.formik?.setFieldValue(name, _)}
        name={name}
        {...rest}
      />
    );
  }
  return null;
}

const CreateForm: FC<CreateFormProps> = (props) => {
  switch (props.data.type) {
    case 'text':
    case 'email':
      return <FormTextBuild {...props} />
    case 'textarea':
      return <FormTextareaBuild {...props} />
    case 'password':
      return <FormPasswordBuild {...props} />
    case 'select':
      return <FormSelectBuild {...props} />
    case 'select-async':
      return <FormSelectAsyncBuild {...props} />
    case 'multi-select':
      return <FormMultiSelectBuild {...props} />
    case 'multi-select-all':
      return <FormMultiSelectAllBuild {...props} />
    case 'radio':
      return <FormRadioBuild {...props} />
    case 'checkbox':
      return <FormCheckboxBuild {...props} />
    case 'checklist':
      return <FormChecklistBuild {...props} />
    case 'date':
      return <FormDatepickerBuild {...props} />
    case 'form-editor':
      return <FormEditorBuild {...props} />
    case 'image':
      return <FormImageBuild {...props} />
    case 'file-csv':
      return <FormFileCsvBuild {...props} />
    default:
      return null
  }
}

export const FormBuilder: FC<GFormProps> = ({ formik, generated, variant = 'ROW', styleTitle = 'normal' }) => {
  const _generated = typeof generated === 'function' ? generated(formik) : generated;
  return (
    <>
      {_generated.map((data, index) => {
        if (data.isShow === null) return true
        if (data.isShow === false) return null

        if (data.type === 'title') {
          return (
            <div key={data.title} className={`font-semibold ${data.className ?? ''}`}>
              <div>{data.title}</div>
              {data.description && (
                <div className='mt-1 font-normal text-fs-9 text-neutral-70'>{data.description}</div>
              )}
            </div>
          )
        }

        if (data.type === 'dashed-divider') {
          return <div className='mb-6 border-b-2 border-dashed border-neutral-30' key={`dashed-divider-${index}`} />;
        }
        return (
          <div
            key={(data.title as string) || index}
            className={data.classNames?.position ? data.classNames?.position : 'grid w-full grid-cols-12 mb-6 gap-x-6'}
          >
            {data.title && (
              <div
                className={cn(
                  getFormLabelStyle(variant),
                  'flex items-center',
                  { 'items-start': data.type === 'image' || data.type === 'form-editor' || data.type === 'textarea' },
                  data.classNames?.title,
                  data.className
                )}
              >
                <FormLabel
                  description={data.description}
                  required={data.required}
                  optional={data.optional}
                  appendIcon={data.appendIcon}
                  typeContent={data.typeContent}
                  styleTitle={styleTitle}
                >
                  {data.title}
                </FormLabel>
              </div>
            )}

            <div className={cn(getFormListStyle(variant), data.classNames?.input)}>
              {Array.isArray(data.value) ? (
                <div
                  className={cn(
                    `items-start w-full`,
                    { block: variant === 'FULL' },
                    { 'md:flex md:space-x-6': variant === 'ROW' },
                    data.className
                  )}
                >
                  {data.value.map((dt, id) => {
                    if (dt.isShow === false) {
                      return null;
                    }

                    if (dt.renderForm) {
                      return (
                        <div
                          key={(dt.value as string) || id}
                          className={cn('mb-4 md:mb-0 flex-1', dt.className, {
                            'md:mb-0': variant === 'ROW',
                          })}
                        >
                          {dt.renderForm}
                        </div>
                      )
                    }

                    return (
                      <CreateForm
                        key={(dt.value as string) || id}
                        data={dt}
                        formik={formik}
                        index={index}
                        arrayValue
                        arrayClassName={cn('mb-4 md:mb-0 flex-1', dt.className, {
                          'md:mb-0': variant === 'ROW',
                        })}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  {data.renderForm ? data.renderForm : <CreateForm data={data} formik={formik} index={index} />}
                </>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
