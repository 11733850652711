import { PaginateModel } from "src/app/models/api.types";
import { safeArray } from "src/app/utils/array-utils";
import { SelectAsyncAdditionalPayload } from "../MultiSelectAll";
import { FormSelect } from "./Select.component";
import { FormSelectAsyncProps } from "./SelectAsync.types";
import { useEffect, useState } from "react";

const initAdditional = {
  limit: 10,
  page: 1
};

export function FormSelectAsync<T>({
  selectOption,
  fetchApi,
  payload,
  reFetch,
  fetchOptions,
  options: optionsProps,
  ...props
}: Readonly<FormSelectAsyncProps<T>>) {
  const [key, setKey] = useState(props.value?.toString());
  const [optionMaster, setOptionMaster] = useState(optionsProps);

  const hasFetchOptions = typeof (fetchOptions) === 'function';
  const hasFetchApi = typeof (fetchApi) === 'function';

  const getLoadOptions = async (
    search: string,
    _: unknown,
    additional: SelectAsyncAdditionalPayload = initAdditional
  ) => {
    try {

      const response = await fetchApi?.({ ...additional, search, ...payload });
      const {
        pagination,
        content
      } = response?.data?.response_output?.list || {};
      const {
        total_pages = 1
      } = pagination ?? {} as PaginateModel;
      const options = safeArray(content).map((_: any, __: number) => selectOption?.(_, __));
      return {
        hasMore: additional?.page < total_pages,
        additional: { ...additional, page: additional.page + 1 },
        options
      };

    } catch (error) {
      return ({
        hasMore: false,
        options: []
      });
    }
  };

  useEffect(
    () => {
      if (hasFetchOptions) {
        fetchOptions()
          .then((response) => {
            const content = safeArray(response?.data?.response_output?.list?.content);
            const options = content.map((_: any, __: number) => selectOption?.(_, __)!);
            setOptionMaster(options);
          })
          .catch((error: any) => {
            console.log("🚀 ~ error:", error)
          });
      }
    },
    []
  );

  useEffect(() => {
    if (reFetch) {
      setKey(prevKey => prevKey + '1');
    }
  }, [payload, reFetch]);

  return (
    <FormSelect
      {...props}
      keyValue={key}
      loadOptions={hasFetchApi ? getLoadOptions : undefined}
      asyncSelect={!hasFetchOptions}
      options={optionMaster}
    />
  );
}