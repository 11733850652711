import clsx from 'clsx'
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { useDebounceEffect } from 'src/app/hooks/debounce-hook'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { ModulePermissionQuery } from 'src/app/modules/permissions/models/Role.model'
import { FormText, GButton } from '../Libs'
import QueryTable from '../Libs/Table/QueryTable/QueryTable'
import TableError from '../Libs/Table/TableError'
import CalendarModal from './ModalCollection/CalendarModal'
import TableFilter from './TableFilter'
import { useClickOutside } from 'src/app/hooks/click-outside-hook'
import { DateObject } from 'react-multi-date-picker'
import dayjs from 'dayjs'

interface EmptyTable {
  title: string
  body: string
}

interface Props {
  renderTable: any
  headers: any
  generatedForm?: any
  filterCount?: number
  formik?: any
  resetFilterFn?: any
  isShow?: any
  setIsShow?: any
  isShowCalendar?: any
  setIsShowCalendar?: any
  isSearch?: boolean
  isFilter?: boolean
  stickyHeader?: boolean
  isPagination?: boolean
  filterCalendar?: boolean
  createButton?: boolean
  titleCalendar?: string
  placeholder?: string
  titleCreateButton?: string
  notFoundType?: 'TYPE_1' | 'TYPE_2'
  onResetFilterClick?: MouseEventHandler
  onCreateButton?: any,
  permissionCreate?: ModulePermissionQuery,
  emptyTable?: EmptyTable
}

const BodyTableV2: FC<Props> = ({
  generatedForm,
  resetFilterFn,
  renderTable,
  headers,
  filterCount = 0,
  isSearch = true,
  isFilter = true,
  filterCalendar = false,
  createButton = false,
  isPagination = true,
  stickyHeader = false,
  titleCalendar,
  placeholder = 'Search',
  notFoundType = 'TYPE_1',
  titleCreateButton = 'Create',
  onResetFilterClick,
  onCreateButton,
  permissionCreate,
  emptyTable,
}) => {

  const [, isShowCalendar, setIsShowCalendar] = useClickOutside(false);
  const [, isShow, setIsShow] = useClickOutside(false);
  const [dateRange, setDateRange] = useState<DateObject[]>([]);

  const formik = renderTable.formikFilter;
  const hasDateRange = !formik.dirty && !!formik.values?.start_date && !!formik.values?.end_date;

  const handleSearchFn = useDebounceEffect(
    useCallback(
      (search: string) => renderTable.setQuery((previus: any) => ({ ...previus, search })),
      [renderTable.setQuery]
    ),
    500
  )

  const { hasAccess } = usePermissions()
  const [selectedDateFilter, setSelectedDateFilter] = useState<any>()


  useEffect(() => {
    if (dateRange !== undefined && dateRange.length === 2) {
      const startDate = dateRange[0].format('YYYY-MM-DD')
      const endDate = dateRange[1].format('YYYY-MM-DD')

      formik.setFieldValue('start_date', startDate)
      formik.setFieldValue('end_date', endDate)
    }
  }, [dateRange.length])


  const handleResetCalendar = () => {
    setDateRange([]);
    setIsShowCalendar(false)
    formik.setFieldValue('start_date', '')
    formik.setFieldValue('end_date', '')
    renderTable.setQuery((prev: any) => ({
      ...prev,
      start_date: null,
      end_date: null
    }));
  };

  const handleSubmitCalendar = () => {
    const startDate = formik.values?.start_date;
    const endDate = formik.values?.end_date;
    setIsShowCalendar(false);
    if (!!startDate && !!endDate) {
      renderTable.setQuery((prev: any) => ({
        ...prev,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD')
      }));
    }
  };

  return (
    <>
      {!renderTable.isError ? (
        <>
          {(!renderTable.isFetching || renderTable.state.query.search !== '') && (
            <div className='flex items-center justify-between mb-4'>

              <div className='flex'>
                {isSearch && (
                  <div className='md:min-w-[300px]'>
                    <FormText
                      placeholder={placeholder}
                      prependInnerIcon='IconSearch'
                      appendInnerIcon={renderTable.state.query.search ? 'IconClose' : undefined}
                      appendInnerClassname='text-neutral-60'
                      clickAppendInnerIcon={() => handleSearchFn('')}
                      onChange={(e) => handleSearchFn(e.target.value)}
                      name='search'
                    />
                  </div>
                )}

                {filterCalendar && (
                  <GButton
                    size='large'
                    variant='OUTLINED'
                    startIcon='IconCalendarRounded'
                    className={clsx('bg-netral border-primary hover:bg-primary-light-1 mx-4', { 'bg-primary-light-2 border-none': hasDateRange })}
                    onClick={() => setIsShowCalendar(!isShowCalendar)}
                  >
                    {hasDateRange ? `${formik.values?.start_date} - ${formik.values?.end_date}` : 'Select Date'}
                  </GButton>
                )}
                {isFilter && (
                  <TableFilter
                    filterCount={filterCount}
                    formik={formik}
                    generatedForm={generatedForm}
                    resetFilterFn={resetFilterFn}
                    isShow={isShow}
                    setIsShow={setIsShow} />
                )}
              </div>
              {createButton && hasAccess(permissionCreate) && (
                <GButton startIcon='IconAdd' onClick={onCreateButton}>
                  {titleCreateButton}
                </GButton>
              )}
            </div>
          )}

          <QueryTable
            render={renderTable}
            headers={headers}
            notFoundType={notFoundType}
            onResetFilterClick={onResetFilterClick}
            showPagination={isPagination}
            isStickyHeader={stickyHeader}
            emptyTable={emptyTable}
          />

          <CalendarModal
            title={titleCalendar || 'Date Filter'}
            isShowCalendar={isShowCalendar}
            setIsShowCalendar={setIsShowCalendar}
            setSelectedDate={setSelectedDateFilter}
            value={selectedDateFilter}
            setValue={setDateRange}
            reset={handleResetCalendar}
            apply={handleSubmitCalendar}
          />
        </>
      ) : (
        <TableError />
      )}
    </>
  )
}

export default BodyTableV2
