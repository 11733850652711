import { QueryKey, QueryOptions, useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useHeaderToast } from '../context/ToastContext'
import { getErrorMessage } from '../utils/api-utils'
import { useOnline } from './online-hook'
import { parseAsArrayOf, parseAsInteger, parseAsString, parseAsStringEnum, useQueryStates, UseQueryStatesKeysMap } from 'nuqs'
import { Order } from '../models/api.types'
import { useFormik } from 'formik'

type UsePaginatorOptions<T, Q> = {
  queryKey: string[]
  queryFn: (params: Q) => any
  enabled?: boolean
  queryOptions?: QueryOptions<T> & QueryKey
  queryStateKeysMap?: UseQueryStatesKeysMap<{ [key in keyof Q]?: any }>
  filterFn?: (values: Q) => Record<string, any>
  filterValidationSchema?: any
}

function normalizeValue<T>(value?: T) {
  if (value === undefined || value === '') {
    return null;
  }
  if (Array.isArray(value)) {
    const values = value.filter((_) => _ !== undefined || _ !== '');
    return values.length > 0 ? values : null;
  }
  return value;
}

function cleanObject<T extends object>(obj = {} as T): T {
  const newObj = Object.entries(obj).reduce(
    (stack, [key, value]) => {
      const _value = normalizeValue(value);
      return { ...stack, [key]: _value };
    },
    {} as T
  );
  return newObj;
}

export function usePaginationQuery<T, Q>(
  {
    filterFn = (_) => _ as any,
    queryStateKeysMap,
    queryFn,
    queryKey,
    enabled,
    queryOptions,
    filterValidationSchema
  }: UsePaginatorOptions<T, Q>
) {

  const { isOnline, errorOffline } = useOnline()
  const { addPageToasts } = useHeaderToast()

  const [total, setTotal] = useState(0);
  const [refetch, setRefetch] = useState<number>();
  const forceUpdate = () => setRefetch(Date.now());

  const [queryState, setQueryState] = useQueryStates({
    page: parseAsInteger.withDefault(1),
    search: parseAsString,
    limit: parseAsInteger.withDefault(10),
    sort: parseAsString,
    order: parseAsStringEnum<Order>(Object.values(Order)),
    start_date: parseAsString,
    end_date: parseAsString,
    statuses: parseAsArrayOf(parseAsString),
    ...(queryStateKeysMap as any)
  });

  const renderParams = { ...queryState, _: refetch } as any;
  const query = useQuery<T>({
    queryKey: [...queryKey, renderParams],
    queryFn: () => queryFn({ ...renderParams }),
    onError: (e) => {
      if (isOnline) addPageToasts({ scheme: 'danger', text: getErrorMessage(e, true) })
      else addPageToasts({ scheme: 'danger', text: errorOffline })
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
    ...queryOptions,
  })

  const setPage = useCallback((page: number) => {
    if (Math.ceil(total / queryState.limit) > 1) {
      setQueryState({ page });
    }
  }, [total, queryState.limit])

  const setQuery = useCallback(
    async <Q extends Record<string, any>>(builder: (query: Q) => Q) => {
      return setQueryState((prev) => {
        const _queryState = builder(prev);
        return {
          ...cleanObject(_queryState),
          page: 1
        }
      })
    },
    []
  );

  const setLimit = useCallback((limit: number) => {
    setQueryState({ page: 1, limit });
  }, [])

  const state = { ...renderParams, query: renderParams, total };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: filterValidationSchema,
    initialValues: state,
    onSubmit: (values) => {
      const payload = filterFn(values);
      setQueryState(payload);
    },
  })

  return {
    state,
    setPage,
    setQuery,
    setLimit,
    setTotal,
    forceUpdate,
    formikFilter: formik,
    ...query,
  }
}
