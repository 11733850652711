import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { Card, GButton } from "src/app/components/Libs";
import { DraggableTable } from "src/app/components/Libs/DraggableTable/DraggableTable";
import EmptyTable from "src/app/components/Widgets/EmptyTable";
import BasicModal from "src/app/components/Widgets/ModalCollection/BasicModal";
import { useHeaderToast } from "src/app/context/ToastContext";
import { useErrorQuery } from "src/app/hooks/error-query-hook";
import { Order } from "src/app/models/api.types";
import { SectionBannerModel } from "../../../models/SectionBanner.model";
import { DETAIL_SECTION_BANNER_KEY, bulkChooseBanner, getListBanner, saveSequenceBanner, updateFeaturedBanner } from "../../../services/Banner.services";
import { useTableFeaturedBanner } from "../../../templates/section_banner/table-data";
import ModalListBanner from "./ModalListBanner";
import { usePermissions } from "src/app/hooks/permissions-hook";
import { useHistory } from "react-router-dom";
import PromotionScreens from "../../../Screens";

const OngoingSection: FC<{ selectedTab?: string }> = () => {
  const { hasAccess } = usePermissions()
  const [modalFeaturedBanner, setModalFeaturedBanner] = useState(false)
  const [data, setData] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<SectionBannerModel>()
  const { addPageToasts } = useHeaderToast()
  const { errorTemp } = useErrorQuery()
  const [listIds, setListIds] = useState([] as string[])
  const [maxData, setMaxData] = useState<number>(0)
  const history = useHistory();

  useEffect(
    () => {
      history.replace(PromotionScreens.LIST_SECTION_BANNER.ONGOING);
    },
    [modalFeaturedBanner]
  );

  const { refetch } = useQuery({
    queryKey: [DETAIL_SECTION_BANNER_KEY],
    queryFn: async () => {
      const response: any = (await getListBanner({ is_featured: true, order: Order.ASC, sort: 'sequence' }, 'section-banner')).data.response_output?.list
      const max = response?.meta?.maximum_featured

      setMaxData(max)
      if (response?.content) setData(response?.content as [])
      return data;
    },
    onError: (e) => errorTemp(e),
  })

  const handleClickFn = (ids: any) => {
    setListIds(ids)
  }

  const saveFeatured = useMutation({
    mutationFn: (payload: any) => bulkChooseBanner(payload, 'section-banner'),
    onSuccess: () => {
      addPageToasts({ scheme: 'success', text: 'Add Featured Banner success' })
      refetch()
      setModalFeaturedBanner(false)
    },
    onError: (e) => errorTemp(e),
  })

  const onReorderData = async (payload: any) => {
    const data = payload.map((row: any, index: number) => ({
      id: row.id,
      sequence: index + 1
    }))
    return saveSequenceBanner(data, 'section-banner')
  }

  const deleteFn = useMutation({
    mutationFn: () => updateFeaturedBanner({ id: selected?.id, is_featured: false }, 'section-banner'),
    onSuccess: () => {
      addPageToasts({ scheme: 'success', text: 'Remove Ongoing Banner success' })
      refetch()
      setDeleteModal(false)
    },
    onError: (e: any) => errorTemp(e.response.data.response_output.errors),
  })

  const { columns } = useTableFeaturedBanner(data, setSelected, setDeleteModal)

  return <div data-testid='active-best-seller'>
    <Card.Header className="flex justify-between items-center">
      <div className="text-sm font-semibold text-neutral-50">List of section banner on the KFCku Homepage</div>
      {hasAccess('section-banner.create') && (
        <GButton startIcon='IconAdd' onClick={() => setModalFeaturedBanner(true)} disabled={data?.length >= maxData}>
          Add Section
        </GButton>
      )}
    </Card.Header>
    <Card.Body>
      <DraggableTable columns={columns} data={data} setData={setData} onReorderData={onReorderData} />
      {data.length < 1 && <EmptyTable title="Featured Banner is empty." body="Please click “Add Featured Banner” to create Featured Banner." />}
    </Card.Body>

    <BasicModal
      show={modalFeaturedBanner}
      handleClose={() => setModalFeaturedBanner(false)}
      handleSuccess={() => saveFeatured.mutate(listIds)}
      header='Choose Section'
      positiveLabel='Save'
      negativeLabel='Cancel'
      disablePositive={!listIds.length}
      loading={saveFeatured.isLoading}
      size='xxl'
    >
      <ModalListBanner onChange={handleClickFn} />
    </BasicModal>

    <BasicModal
      show={deleteModal}
      handleClose={() => setDeleteModal(false)}
      handleSuccess={() => deleteFn.mutate()}
      header='Are you sure want to remove this Section Banner?'
      positiveLabel='Remove'
      negativeLabel='Cancel'
      loading={deleteFn.isLoading}
    >
      Are you sure want to remove {selected?.name}? This will remove section banner from KFCku Apps Homepage.
    </BasicModal>
  </div>
}

export default OngoingSection