import { useHeaderToast } from "../context/ToastContext";

export interface UseCopyTextParams {
  successMessage?: string;
  errorMessage?: string;
  scheme?: 'info' | 'danger' | 'success' | 'primary' | 'warning';
  content?: string;
}

export function useCopyText({
  successMessage = 'Text copied.',
  errorMessage = 'Failed to copy text.',
  scheme = 'info',
  content = ''
}: UseCopyTextParams = {}) {

  const { addPageToasts } = useHeaderToast()

  const copy = async (_content = '') => {
    try {

      await navigator.clipboard.writeText(_content || content);
      addPageToasts({
        scheme: scheme,
        text: successMessage,
        fixed: true
      });

    } catch (error) {
      addPageToasts({
        scheme: 'danger',
        text: errorMessage,
        fixed: true
      })
    }
  };

  return copy;
}
