import { useMutation } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { FC, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { FormLabel, FormPassword, FormText, GAlert, GButton } from 'src/app/components/Libs'
import { FormRecaptchaV2 } from 'src/app/components/Libs/Form/Custom'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import { VTooltip } from 'src/app/components/Libs_v2'
import { useWebTitle } from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import AuthRedux from '../../redux/AuthRedux'
import { loginByEmail } from '../../services/Auth.services'

const errorMessageAuth = 'Please input your registered email and password'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Make sure the email address you entered is correct.')
    .required('Email cannot empty'),
  password: Yup.string().required('Password cannot empty'),
  token: Yup.string().required('Please verify you are not a robot'),
})

const initialValues = {
  email: '',
  password: '',
  token: '',
}

const LoginEmail: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useWebTitle('Login')

  const captchaRef = useRef<ReCAPTCHA>(null)
  const [isVerified, setIsVerified] = useState<boolean>(false)


  const handleRecaptchaVerify = (token: string | null): void => {
    formik.setFieldValue('token', token)
    setIsVerified(!!token)
    setTimeout(() => {
      formik.validateField('token')
    }, 100)
  }

  const [QRImage, setQRImage] = useState<string>('')
  const [secretKey] = useState('ZPAKGJAFJHX12WEUTGJS14')

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return loginByEmail(payload)
    },
    onSuccess: (result) => {
      const data = result.data.response_output.detail
      const {access_token, refreshtoken, is_2fa_active} = data

      dispatch(AuthRedux.actions.set2fa(access_token, refreshtoken, is_2fa_active))
      if (!is_2fa_active) {
        history.replace(`/auth/login-2fa/set-up`)
        return
      }else{
        history.replace(`/auth/login-2fa/verify`)
        return
      }
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setErrors({
          email: errorMessageAuth,
          password: errorMessageAuth
        });
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='login-email-page' className='w-full'>
      <div className='mb-8'>
        <div className='font-national2Condensed font-bold text-fs-4'>Sign in Account</div>
      </div>

      {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel className='mb-2'>
            Email
            <div
              data-tooltip-id={`MultilineBlackTooltip1`}
              data-place='bottom'
              className='cursor-pointer ml-2 w-5 h-5'
            >
              <GIcon icon='IconInfoNoBackground' onClick={() => { }} />
            </div>
          </FormLabel>
          <FormText
            {...formik.getFieldProps('email')}
            prependInnerIcon='IconEmail'
            inputClassName='rounded-xl'
            name='email'
            type='email'
            placeholder='Input your email address'
            maxLength={50}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </div>

        <div className='mb-8'>
          <FormLabel className='mb-2'>
            Password
            <div
              data-tooltip-id={`MultilineBlackTooltip2`}
              data-place='bottom'
              className='cursor-pointer ml-2 w-5 h-5'
            >
              <GIcon icon='IconInfoNoBackground' onClick={() => { }} />
            </div>
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            prependInnerIcon='IconLock'
            inputClassName='rounded-xl'
            name='password'
            placeholder='Input your password'
            minLength={8}
            error={formik.errors.password}
            touched={formik.touched.password}
          />

          <div className='pb-2 mt-4 font-medium text-end text-primary text-fs-9'>
            <Link to={AuthScreens.FORGOT.PATH}>
              <span className='cursor-pointer underline'>Forgot Password?</span>
            </Link>
          </div>

          <div className='mb-6 mt-4 flex justify-center'>
            <FormRecaptchaV2
              ref={captchaRef}
              onVerify={handleRecaptchaVerify}
              isError={!isVerified && !formik.isValid || formik.errors.token !== undefined}
              errorMessage='Please verify you are not a robot'
            />
          </div>
        </div>

        <GButton
          type='submit'
          size='large'
          className='w-full'
          loading={submitFn.isLoading}
          disabled={submitFn.isLoading}
        >
          Sign In
        </GButton>
      </form>

      <VTooltip
        tooltipId={`MultilineBlackTooltip1`}
        variant='DARK'
        position='bottom-start'
        noArrow={true}
        typeContent='email'
      ></VTooltip>
      <VTooltip
        tooltipId={`MultilineBlackTooltip2`}
        variant='DARK'
        position='bottom-start'
        noArrow={true}
        typeContent='password'
      ></VTooltip>
    </div>
  )
}

export default LoginEmail
